import React from "react";
import axios from "axios";
// import { checkJwt, checkAdminJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Footerbar from "../../Layouts/Footerbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Sidebar from '../../Layouts/Sidebar';
import { Table } from "react-bootstrap";


const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Mandate extends React.Component {    
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false,
            excelReport: [],
            startDate: "",
            endDate: "",
            transaction_status: "",
            fileExtension : ".xlsx",
            fileType : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        };
    }
    
    componentDidMount = async() => {       
        // const token = checkJwt();
        const response = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/mandate`, 
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				}
			}
		)
        // const dataSet = response.data.transactions.map((item) => (
        //     [item.trans_id, item.order_id, item.full_name, item.amount, item.transaction_status, item.narration, item.created_at]
        // ));

        this.setState({
            items: response.data.data,
            DataisLoaded: true,
        });
    }

    exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: this.state.fileType });
        FileSaver.saveAs(data, fileName + this.state.fileExtension);
    };

    handleExcelReport = (e, startDate, endDate, transaction_status) => {
        // preventDefault();
        e.preventDefault();

        // const token = checkJwt();
        if(startDate == null || startDate === "") startDate = "2020-01-01";
        if(endDate == null || endDate === "") endDate = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`,
            {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: "Bearer " + token
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    status: transaction_status
                }
            }
        ).then((response) => {
            // console.log({response, countResponseVale: response.data.transactions.length});
            if(response.data.transactions.length > 0){
                this.setState({
                    excelReport: response.data.transactions
                });
                this.exportToCSV(response.data.transactions, "transaction_report_from_" + startDate + "_to_" + endDate);
            }else{
                alert("No record found");
            }
            this.setState({
                excelReport: response.data.transactions
            });
        });
    }
    handlePDFReport = (e, startDate, endDate, transaction_status) => {
        // preventDefault();
        e.preventDefault();
        // const token = checkJwt();
        if(startDate == null || startDate === "") startDate = "2020-01-01";
        if(endDate == null || endDate === "") endDate = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`,
            {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: "Bearer " + token
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    status: transaction_status
                }
            }
        ).then((response) => {
            // console.log({response});
        });
    }



    render() {
        return (
            <React.Fragment>
                <Navbar/>
				<div id="layoutSidenav">
				<Sidebar/>
                    <div className="bg-white" id="layoutSidenav_content">
                
                    <main>
                        <div className="container-fluid px-4">
                            <h3 className="mt-4">Mandates</h3>
                            <hr/>
                        
                            <div className="card mb-4">
                                <div className="card-header">
                                    <i className="fas fa-table me-1"></i>
                                    Mandates
                                </div>
                                <div className="card-body">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Account Name</th>         
                                                <th>Account No </th>
                                                <th>Mandate Code</th>
                                                <th>Bank Code</th>
                                                <th>Trans ID</th>
                                            </tr>
                                        </thead>
                                    
                                        <tbody>
                                        {this.state.items.length > 0 ? (
                                            this.state.items.map((item) => (
                                                <tr>
                                                    <td>{item.AcctName}</td>
                                                    <td>{item.AcctNumber}</td>
                                                    <td>{item.MandateCode}</td>
                                                    <td>{item.bankCode}</td>
                                                    <td>{item.BillerTransId}</td>
                                                </tr>
                                            ))
                                            ) : (
                                                <tr className="text-center">
                                                    <td colSpan={7}>Loading...</td>
                                                </tr>
                                            )}
                                    
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </main>

                <Footerbar/>
					</div>
				</div>	
            </React.Fragment>
        );
    }
}
export default Mandate;
