import React from "react";
import axios from "axios";
import { checkJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Footerbar from "../../Layouts/Footerbar";
import { Switch } from "react-router-dom";
import Sidebar from '../../Layouts/Sidebar';
import { Table } from "react-bootstrap";
// import '../../../public/assets/css/styles.css';
// import routes from "../Routes/routes";

const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Dashboard extends React.Component {
	constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false,
			transactionCount : 'Loading...',
			transactionValue : 'Loading...',
			totalMerchantCount : 'Loading...',
			latestMerchant : [],
			latestTransaction : [],
			thisYearCount : [],
			page: 1,
			pagesize: 10,
			yearTillDateCount: [],
            period: {
				week: "This Week",
				month: "This Month",
				year: "This Year",
				custom: "Custom"
			},
        };
    }
    
    componentDidMount = async() => {       
		// const token = checkJwt(); 
		const transactionCount = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/total_transaction`, 
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				},
				// params: {"period": this.state.period.week}
			}
		);
		const transactionValue = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction_value`, 
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				},
				// params: {"period": this.state.period.week}
			}
		);
		const totalMerchantCount = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/total_merchant`, 
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				},
				// params: {"period": this.state.period.week}
			}
		);
		const latestMerchant = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchants`, 
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				},
				params: {
					page: this.state.page,
					pagesize: this.state.pagesize 
				}
			}
		);
		const transactions = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction_details`,
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				},
				params: {
                    page: 1,
                    pagesize: 10
                }
			}
		);
		const numberWithCommas = (x) => {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		};
		
        this.setState({
            items: [],
			transactionCount: transactionCount.data.toLocaleString(navigator.language, { minimumFractionDigits: 0 }),	
			transactionValue: numberWithCommas(transactionValue.data),
			totalMerchantCount: numberWithCommas(totalMerchantCount.data),
			latestMerchant: latestMerchant.data,
			latestTransaction: transactions.data,
            DataisLoaded: true,
        });
		

    }

	render() {
		return (
			<React.Fragment>
				<Navbar/>
				<div id="layoutSidenav">
				<Sidebar/>
					<div className="bg-white" id="layoutSidenav_content">
						<main>
							<div className="container-fluid px-4">
								<h1 className="mt-4">Dashboard</h1>
								<hr/>
								<div className="row">
									<div className="col-xl-4 col-md-6">
										<div className="card bg-primary text-white mb-4">
											<div className="card-body"><h3>
											{this.state.totalMerchantCount}
											</h3>
												
											</div>
											<div className="card-footer d-flex align-items-center justify-content-between">
												<h4 className="small text-white " href="#">Registered Merchant</h4>
												<div className="small text-white"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-md-6">
										<div className="card bg-warning text-dark mb-4">
											<div className="card-body"><h3>
											{this.state.transactionCount}
											</h3></div>
											<div className="card-footer d-flex align-items-center justify-content-between">
												<h4 className="small text-dark" href="#">
													Total Transactions Count
												</h4>
												<div className="small text-white"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-md-12">
										<div className="card bg-success text-white mb-4">
											<div className="card-body"><h3>
											{this.state.transactionValue}
											</h3></div>
											<div className="card-footer d-flex align-items-center justify-content-between">
												<h4 className="small text-white" href="#">Transaction Value</h4>
												<div className="small text-white"></div>
											</div>
										</div>
									</div>
								</div>
								<hr/>
								<div className="row">
									<div className="col-xl-6">
										<div className="card mb-4">
											<div className="card-header">
												<i className="fas fa-table me-1"></i>
												Recent Merchants
											</div>
										<div className="card-body">
										
											<Table striped bordered hover>
												<thead>
													<tr className="text-center">
														<th>Business Name</th>
														<th>Email</th>
														<th>Date Registered</th>
													</tr>
												</thead>
												<tbody>
												{this.state.latestMerchant.length > 0 ? (
													this.state.latestMerchant.map((item) => (
														<tr className="text-center">
															<td>{item.businessname}</td>
															<td>{item.email}</td>
															<td>{item.dateregistered.split(' ')[0]}</td>
														</tr>
													))
													) : (
														<tr className="text-center">
															<td colSpan={6}>Loading...</td>
														</tr>
													)}
												</tbody>
											</Table>
											
										</div>
										</div>
									</div>
									<div className="col-xl-6">
										<div className="card mb-4">
											<div className="card-header">
												<i className="fas fa-table me-1"></i>
												Recent Transactions
											</div>
										<div className="card-body">
										
											<Table striped bordered hover>
												<thead>
													<tr className="text-center">
														<th>Merchant ID</th>
														<th>Amount</th>
														<th>Currency</th>
														<th>Date</th>
													</tr>
												</thead>
												<tbody>
												{this.state.latestTransaction.length > 0 ? (
													this.state.latestTransaction.map((item) => (
														<tr className="text-center">
															<td>{item.merchant_id}</td>
															<td>{item.amount}</td>
															<td>{item.currency}</td>
															<td>{item.order_time.split(' ')[0]}</td>
														</tr>
													))
													) : (
														<tr className="text-center">
															<td colSpan={6}>Loading...</td>
														</tr>
													)}
												</tbody>
											</Table>
											
										</div>
										</div>
									</div>
								</div>
								
							</div>
						</main>
						<Footerbar/>
					</div>
				</div>	
			</React.Fragment>
		);
	}
}

export default Dashboard;
