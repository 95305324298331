import React from "react";
import axios from "axios";
import { checkJwt, checkAdminJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Table from 'react-bootstrap/Table';
import Footerbar from "../../Layouts/Footerbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Sidebar from '../../Layouts/Sidebar';


const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class PartnerTransaction extends React.Component {    
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false,
            excelReport: [],
            startDate: "",
            endDate: "",
            transaction_status: "",
            fileExtension : ".xlsx",
            fileType : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        };
    }
    
    componentDidMount = async() => {       
        // const token = checkJwt();
        const partnerTransaction = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/partner_transactions`, 
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				}
			}
		)
        // const dataSet = response.data.transactions.map((item) => (
        //     [item.trans_id, item.order_id, item.full_name, item.amount, item.transaction_status, item.narration, item.created_at]
        // ));
       
        this.setState({
            items: partnerTransaction.data,
            DataisLoaded: true,
        });

        console.log({p_trans: partnerTransaction.data});
    }

    exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: this.state.fileType });
        FileSaver.saveAs(data, fileName + this.state.fileExtension);
    };

    handleExcelReport = (e, startDate, endDate, transaction_status) => {
        // preventDefault();
        e.preventDefault();

        // const token = checkJwt();
        if(startDate == null || startDate === "") startDate = "2020-01-01";
        if(endDate == null || endDate === "") endDate = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`,
            {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: "Bearer " + token
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    status: transaction_status
                }
            }
        ).then((response) => {
            // console.log({response, countResponseVale: response.data.transactions.length});
            if(response.data.transactions.length > 0){
                this.setState({
                    excelReport: response.data.transactions
                });
                this.exportToCSV(response.data.transactions, "transaction_report_from_" + startDate + "_to_" + endDate);
            }else{
                alert("No record found");
            }
            this.setState({
                excelReport: response.data.transactions
            });
        });
    }
    handlePDFReport = (e, startDate, endDate, transaction_status) => {
        // preventDefault();
        e.preventDefault();
        // const token = checkJwt();
        if(startDate == null || startDate === "") startDate = "2020-01-01";
        if(endDate == null || endDate === "") endDate = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`,
            {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: "Bearer " + token
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    status: transaction_status
                }
            }
        ).then((response) => {
            // console.log({response});
        });
    }



    render() {
        return (
            <React.Fragment>
                <Navbar/>
				<div id="layoutSidenav">
				<Sidebar/>
                    <div className="bg-white" id="layoutSidenav_content">
                
                    <main>
                        <div className="container-fluid px-4">
                        <h4 className="mt-4">Partner Transactions</h4>
                        <div className="row">
                            <hr/>
                            <div className="col-md-12">
                            <h5>Filter Report Criteria</h5>
                            <form>
                                <div className="row">
                                    <div className="col-md-2">
                                    <label>From</label>
                                    <input type="date" name="from" className="form-control"/>
                                    </div>
                                    <div className="col-md-2">
                                        <label>To</label>
                                        <input type="date" name="to" className="form-control"/>
                                    </div>
                                    <div className="col-md-2">
                                        <label>Merchant Bank</label>
                                        <select name="bank" id="bank" className="form-control">
                                            <option value="">name</option>';
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Merchants</label>
                                        <select name="merchant" className="form-control" id="merchants">
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <input type="submit" name="pdf" id="pdf" value="PDF Report" className="btn btn-primary btn-fixed" style={{ marginTop: "25px" }}/>
                                        <input type="submit" name="excel" id="excel" value="Excel Report" className="btn btn-success btn-fixed" style={{ marginTop: "25px" }}/>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                        <hr />
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Partner Transactions
                            </div>
                            <div className="card-body">
                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>Merchant</th>
                                            <th>Customer</th>
                                            <th>Amount</th>
                                            <th>Currency</th>
                                            {/* <th>Description</th> */}
                                            <th>Status</th>
                                            <th>Date</th>       
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.items.length > 0 ? (
                                            this.state.items.map((item) => (
                                                <tr>
                                                    <td>{item.merchant_id}</td>
                                                    <td>{item.full_name}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.currency}</td>
                                                    <td>{item.transaction_status}</td>
                                                    <td>{item.created_at.split(',')[0]}</td>
                                                </tr>
                                            ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6">No transaction yet</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                    </main>

                <Footerbar/>
					</div>
				</div>	
            </React.Fragment>
        );
    }
}
export default PartnerTransaction;
