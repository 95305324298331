import React from "react";
import axios from "axios";
import Navbar from "../../Layouts/Navbar";
import Footerbar from "../../Layouts/Footerbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Sidebar from '../../Layouts/Sidebar';
import { Table } from "react-bootstrap";


const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
const REACT_APP_Visa_NGN_Account = process.env.REACT_APP_Visa_NGN_Account; 
const REACT_APP_Visa_USD_Account = process.env.REACT_APP_Visa_USD_Account; 
const REACT_APP_NGN_SUS_Account = process.env.REACT_APP_NGN_SUS_Account; 
const REACT_APP_USD_SUS_Account = process.env.REACT_APP_USD_SUS_Account; 
class WemaSettlement extends React.Component {    
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false,
            excelReport: [],
            start: "",
            end: "",
            account_number : '',
            transaction_status: "",
            fileExtension : ".xlsx",
            fileType : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        };
    }
    
    componentDidMount = async() => {   
    }

    exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: this.state.fileType });
        FileSaver.saveAs(data, fileName + this.state.fileExtension);
    };
    
    handleExcelReport = (e, start, end, accountNumber) => {
        e.preventDefault();

        // if(start == null || start === "") start = "2020-01-01";
        // if(end == null || end === "") end = new Date().toISOString().slice(0, 10);
        let apiKey = `${process.env.REACT_APP_WEMA_API_KEY}`;
        console.log({
            accountNumber,
            apiKey
        })
        axios.post(
            `${process.env.REACT_APP_WEMA_BASE_URL}/AccountStatement`,
                {
                    "vendorid": "NetPlus",
                    "accountno": `${accountNumber}`,
                    "startdate": "01/11/2022",
                    "enddate": "01/04/2023"
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "apikey": apiKey
                    },
                }
                
        ).then((response) => {
            alert('Under processing...')
            console.log({response, responseData: response.data});
            // if(response.data.transactions.length > 0){
            //     this.setState({
            //         excelReport: response.data.transactions
            //     });
            //     // this.exportToCSV(response.data.transactions, "transaction_report_from_" + startDate + "_to_" + endDate);
            // }else{
            //     alert("No record found");
            // }
            // this.setState({
            //     excelReport: response.data.transactions
            // });
        }).catch((err)=> {
            console.log(err)
        });
    }
    handlePDFReport = (e, startDate, endDate, transaction_status) => {
        e.preventDefault();
        if(startDate == null || startDate === "") startDate = "2020-01-01";
        if(endDate == null || endDate === "") endDate = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    status: transaction_status
                }
            }
        ).then((response) => {
            // console.log({response});
        });
    }
    

    handleOnChange = (e, value) =>{
        e.preventDefault();
        let accountNumber = '';
        if(value == 'Visa_NGN_Account')
            accountNumber = REACT_APP_Visa_NGN_Account
        else if(value == 'Visa_USD_Account')
            accountNumber = REACT_APP_Visa_USD_Account
        else if(value == 'NGN_SUS_Account')
            accountNumber = REACT_APP_NGN_SUS_Account
        else if(value == 'USD_SUS_Account')
            accountNumber = REACT_APP_USD_SUS_Account
       
        this.setState({
            account_number: accountNumber
        });
        
    }



    render() {
        return (
            <React.Fragment>
                <Navbar/>
				<div id="layoutSidenav">
				<Sidebar/>
                    <div className="bg-white" id="layoutSidenav_content">
                    <main>
	<div className="container-fluid px-4">
		<h4 className="mt-4">Wema Settlements</h4>
        <hr/>
    <div className="col-md-12">
    <h5>Report Filter</h5>
        <form>
            <div className="row">
                <div className="col-md-2">
                    <label>From</label>
                    <input type="date" name="start" className="form-control"/>
                </div>
                <div className="col-md-2">
                    <label>To</label>
                    <input type="date" name="end" className="form-control"/>
                </div>
                <div className="col-md-2">
                    <label>Account ID</label>
                        <select name="account_name" id="account_name" onChange={(event) =>this.handleOnChange(event, event.target.value)} className="form-control">
                            <option value="">Select Account ID</option>
                            <option value="Visa_NGN_Account">Visa NGN Account</option>
                            <option value="Visa_USD_Account">Visa USD Account</option>
                            <option value="NGN_SUS_Account">NGN SUS Account</option>
                            <option value="USD_SUS_Account">USD SUS Account</option>
                        </select>
                </div>
                <div className="col-md-3">
                    <label>Account Number</label>
                    <input type="text" name="account_number" value={this.state.account_number} disabled={true} className="form-control" id="account_number" />
                </div>
                <div className="col-md-3">
                    <input type="submit" name="pdf" value="PDF Report" className="btn btn-primary btn-fixed" style={{ marginTop: "25px" }}/>
                    <input 
                        type="submit" name="excel" value="Excel Report" 
                        className="btn btn-success btn-fixed" style={{ marginTop: "25px" }}
                        onClick={(event) => this.handleExcelReport(event, this.state.start, this.state.end, this.state.account_number)}
                    />
                </div>
            </div>
      </form>
      </div>
  <hr/>
		<div className="card mb-4">
			<div className="card-header">
				<i className="fas fa-table me-1"></i>
				Settlement Accounts
			</div>
			<div className="card-body">
				<Table striped bordered hover>
					<thead>
						<tr>
                            <th>Account ID</th>
                            <th>Account Number</th>     
						</tr>
					</thead>
                    <tbody>
                        <tr>
                            <td> Visa NGN Account </td>
                            <td> 999NGN45200097</td>
                        </tr>
                        <tr>
                            <td> Visa USD Account </td>
                            <td> 999USD45200097</td>
                        </tr>
                        <tr>
                            <td> NGN SUS Account </td>
                            <td> 999NGN45200103</td>
                        </tr>
                        <tr>
                            <td>USD SUS Account</td>
                            <td>999USD45200103</td>
                        </tr>
                    </tbody>
				</Table>
			</div>
		</div>
	</div>

</main>
                
                <Footerbar/>
					</div>
				</div>	
            </React.Fragment>
        );
    }
}
export default WemaSettlement;
