import React from "react";
import axios from "axios";
// import { checkJwt, checkAdminJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Footerbar from "../../Layouts/Footerbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Sidebar from "../../Layouts/Sidebar";
import { Table } from "react-bootstrap";
import Fuse from "fuse.js";
import RenderModal from "../Modals/ViewMerchantModal";

const REACT_APP_BASE_NETPLUS_MERCHANT_URL =
  process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Merchant extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
      excelReport: [],
      merchants: [],
      merchantSearch: [],
      startDate: "",
      endDate: "",
      page: 1,
			pagesize: 10,
      transaction_status: "",
      fileExtension: ".xlsx",
      fileType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    };
  }

  componentDidMount = async () => {
    // const token = checkJwt();
    const merchants = await axios.get(
      `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchants`,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token
        },
        params: {
          page: this.state.page,
					pagesize: this.state.pagesize 
        }
      }
    );

    const merchantSearch = await axios.get(
      `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchants_all`,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token
        },
      }
    );


    this.setState({
      merchants: merchants.data,
      merchantSearch: merchantSearch.data,
      DataisLoaded: true,
    });
  };

  exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: this.state.fileType });
    FileSaver.saveAs(data, fileName + this.state.fileExtension);
  };

  handleExcelReport = (e) => {
    e.preventDefault();

    if(this.state.merchantSearch.length > 0){
      this.exportToCSV(
        this.state.merchantSearch,
        'merchant_list'
      )
    }else{
      alert("processing...")
    }
  };
  handleOnSearch = (e, search) =>{
    e.preventDefault();
    const fuse = new Fuse(this.state.merchantSearch, {
        keys: [
            'merchantid', 'businessname', 
            'email', 'fullname'
        ],
        includeScore: true,
        includeMatches: true,
    });

    const results = fuse.search(search);
    console.log({len: results.length});
    if(results.length > 0){
        console.log({data: results.map((res => res.item))});
            this.setState({
                merchants: results.map((res => res.item))
            })
        // )
    }else{
        this.setState({
            merchants: this.state.merchants
        })
    }
}

onNextPage= async()=>{
  this.setState({
      page: this.state.page+1
  })
  const merchants = await axios.get(
  `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchants`,
      {
        headers: {
          "Content-Type": "application/json",
        },
                params: {
                    page: this.state.page + 1,
                    pagesize: this.state.pagesize
                }
      }
      );
        this.setState({
            merchants: merchants.data
        })
        // console.log({page: this.state.page , merchants: merchants.data });
}

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div id="layoutSidenav">
          <Sidebar />
          <div className="bg-white" id="layoutSidenav_content">
            <main>
              <div class="container-fluid px-4">
                <h3 class="mt-4">Merchants</h3>
                <hr />
                <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-5">
                <input 
                      type="submit" name="excel" value="Export Merchant" 
                      className="btn btn-success btn-fixed"
                      onClick={(event) => this.handleExcelReport(event)}
                  />
                </div>
                <div class="card mb-4">
                  <div class="card-header">
                    <i class="fas fa-table me-1"></i>
                    Merchants
                  </div>
                  <div class="card-body">
                    <div>
                        <input name="" id="" onChange={(event) =>this.handleOnSearch(event, event.target.value)} className="form-control mb-3" type="text" placeholder="Search..." />
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          {/* <th>Action</th> */}
                          <th>Merchant ID</th>
                          <th>Business Name</th>
                          <th>Email</th>
                          <th>Full Name</th>
                          <th>Confirmed</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.merchants.length > 0 ? (
                          this.state.merchants.map((item) => (
                            <tr>
                              {/* <td> */}
                                {/* <RenderModal  /> */}
                              {/* </td> */}
                              <td>{item.merchantid}</td>
                              <td>{item.businessname}</td>
                              <td>{item.email}</td>
                              <td>{item.fullname}</td>
                              <td>{item.status == 1 ? "true" : "false"}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={7}>Loading...</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                      <div>
                          <nav aria-label="Page navigation example">
                          <ul class="pagination justify-content-center">
                              <li class="page-item disabled">
                              <button className="page-link" href="#" tabindex="-1">Previous</button>
                              </li>
                              <li className="page-item"><button className="page-link" onClick={this.onNextPage}>Next</button></li> 
                          </ul>
                          </nav>
                      </div>
                  </div>
                </div>
              </div>
            </main>
            <Footerbar />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Merchant;
