import React from "react";
import axios from "axios";
// import { checkJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Sidebar from "../../Layouts/Sidebar";
import Footerbar from "../../Layouts/Footerbar";
import { Table } from "react-bootstrap";
import EditAssignedModal from "../Modals/EditAssignedModal";
import AssignModal from "../Modals/AssignModal";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const REACT_APP_BASE_NETPLUS_MERCHANT_URL =
  process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class UnassignBank extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assigned: [],
      banks: [],
      excelReport: [],
      DataisLoaded: false,
      textValue: "",
      url_status: "assigned",
      fileExtension : ".xlsx",
      fileType : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    };
  }

  componentDidMount = async () => {
    // const token = checkJwt();
    const response = await axios.get(
      `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchants_assignment_status/${window.location.href.split('/')[5]}`,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token
        },
      }
    );
    console.log({assigned: response.data, url: window.location.href.split('/')[5]});
    this.setState({
        assigned: response.data.data,
        DataisLoaded: true,
    });
  
    this.setState({
        url_status: window.location.href ? window.location.href.split('/')[5] : 'assigned',
        DataisLoaded: true,
    });
  };
  handleData = (e) => {
    e.preventDefault();
    console.log(this.state.data);
  };
  handleChange = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: this.state.fileType });
    FileSaver.saveAs(data, fileName + this.state.fileExtension);
  };

  handleExcelReport = (e, status) => {
    e.preventDefault();
 
    axios.get(
        `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchants_assignment_status/${status}`,
        {
            headers: {
                "Content-Type": "application/json",
                // Authorization: "Bearer " + token
            }
        }
    ).then((response) => {
        console.log({response, countResponseVale: response.data.data});
        let dataRes = response.data.data
        if(dataRes.length > 0){
            this.setState({
                excelReport: dataRes
            });
            this.exportToCSV(dataRes, "Bank_" +status+ "_Merchant");
        }else{
            alert("No record found");
        }
        this.setState({
            excelReport: dataRes
        });
    });
}


 

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div id="layoutSidenav">
          <Sidebar />
          <div className="bg-white" id="layoutSidenav_content">
            <main>
              <div className="container-fluid px-4">
                <h3 className="mt-4">
                {this.state.url_status == 'assigned' ?
                  'Assigned Merchant' : 'Unassigned Merchant'
                }
                </h3>

                <hr />
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-5">
                {this.state.url_status == 'assigned' ? (
                         <input 
                            type="submit" name="excel" value="Download Assigned Merchant" 
                            className="btn btn-secondary"
                            onClick={(event) => this.handleExcelReport(event, this.state.url_status)}
                        />
                    ):(
                        <input
                            type="submit" name="excel" value="Download Unassigned Merchant" 
                            className="btn btn-secondary"
                            onClick={(event) => this.handleExcelReport(event, this.state.url_status)}
                        />
                    )}
                  {this.state.url_status == 'assigned' ? (
                     <a href="/#/assign-bank/unassigned" className="btn btn-success">
                        View Unassigned Merchants
                     </a>
                  ):(
                    <a href="/#/assign-bank/assigned" className="btn btn-success">
                         View Assigned Merchants
                    </a>
                  )}
                  
                 
                </div>
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    {this.state.url_status == 'assigned' ?
                      "Merchant Assigned to Bank" : "Merchant Awaiting Assign"
                    }
                  </div>
                  <div className="card-body">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                        {this.state.url_status == 'assigned' ? (
                            <th className="text-center">Change Bank</th>
                        ): (
                            <th className="text-center">Assign Bank</th>
                        )}

                          <th className="text-center">Merchant ID</th>
                          <th className="text-center">Merchant Name</th>
                          <th className="text-center">Business Name</th>
                          <th className="text-center">Bank Assigned</th>
                        </tr>
                      </thead>

                      <tbody>
                            {this.state.assigned.length > 0 ? (
                                this.state.assigned.map((item) => (
                                    <tr>
                                           {this.state.url_status == 'assigned' ? (
                                            <EditAssignedModal 
                                            businessname={item.businessname}
                                            merchantid={item.merchantid}
                                            />
                                          ):(
                                            <AssignModal 
                                                businessname={item.businessname}
                                                merchantid={item.merchantid}
                                            />
                                          )
                                        }
                                        <td>{item.merchantid}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.businessname}</td>
                                        <td>{item.bank_name ? item.bank_name : 'Null' }</td>
                                      
                                    </tr>
                                ))
                                ) : (
                                    <tr className="text-center">
                                        <td colSpan={7}>Loading...</td>
                                    </tr>
                            )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>

              {/* assigned /unassigned */}
              <div
                className="modal fade"
                id="addModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myModalLabel">
                        Assign Bank to Merchant
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                    </div>

                    <form method="post">
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">
                                Merchant Business Name
                              </label>
                              <input type="hidden" name="id" value="" />
                              <input
                                type="text"
                                className="form-control"
                                readonly
                                value=""
                                name="businessname"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">
                                Merchant ID
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                readonly
                                value=""
                                name="merchantid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">Bank</label>
                              <select
                                className="form-control"
                                name="bank"
                                id="bank"
                              >
                                <option value="">Select Bank</option>
                                <option value="<?php echo $bank->id ?>">
                                  bank
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <input
                          type="submit"
                          className="btn btn-success"
                          value="Save"
                        />
                        <button
                          type="button"
                          className="btn btn-default"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* assigned /unassigned */}
              <div
                className="modal fade"
                id="editModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myModalLabel">
                        Edit Merchant Bank
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                    </div>

                    <form method="post">
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">
                                Merchant Business Name
                              </label>
                              <input type="hidden" name="id" value="dd" />
                              <input
                                type="text"
                                className="form-control"
                                readonly
                                value=""
                                name="businessname"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">
                                Merchant ID
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                readonly
                                value=""
                                name="merchantid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">Bank</label>
                              <select
                                className="form-control"
                                name="bank"
                                id="bank"
                              >
                                <option value="">Select Bank</option>
                                <option value="" selected></option>
                                <option value=""></option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <input
                          type="submit"
                          className="btn btn-danger"
                          value="Update"
                        />
                        <button
                          type="button"
                          className="btn btn-default"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>

            <Footerbar />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default UnassignBank;
