import React from "react";
import axios from "axios";
import { checkJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Sidebar from "../../Layouts/Sidebar";
import Footerbar from "../../Layouts/Footerbar";
import { Table } from "react-bootstrap";
import AddBankModal from "../Modals/AddBankModal";
import Fuse from "fuse.js";

const REACT_APP_BASE_NETPLUS_MERCHANT_URL =
  process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Bank extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      banks: [],
      fullname: "",
      businessname: "",
      email: "",
      currentpassword: "",
      newpassword: "",
      phone: "",
      accountno: "",
      website: "",
      DataisLoaded: false,
      textValue: "",
      webhook_url: "",
    };
  }

  componentDidMount = async () => {
    // const token = checkJwt();
    const response = await axios.get(
      `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/banks`,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token
        },
      }
    );
    console.log(response.data);
    this.setState({
      data: response.data,
      DataisLoaded: true,
    });
   
  };

  handleNewBank = async(bankName)=>{
    const response = await axios.post(
      `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/add_bank`,
      {
				name: bankName, 
			}, 
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token
        },
      }
    );
  }

  handleOnSearch = (e, search) =>{
    e.preventDefault();
    const fuse = new Fuse(this.state.data, {
        keys: [
            'name',
        ],
        includeScore: true,
        includeMatches: true,
    });

    const results = fuse.search(search);
    console.log({len: results.length});
    if(results.length > 0){
        console.log({data: results.map((res => res.item))});
            this.setState({
                data: results.map((res => res.item))
            })
        // )
    }else{
        this.setState({
            data: this.state.data
        })
    }
}
  

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div id="layoutSidenav">
          <Sidebar />
          <div className="bg-white" id="layoutSidenav_content">
            <main>
              <div className="container-fluid px-4">
                <h3 className="mt-4">Bank List</h3>
                <hr />
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-5">
                 <AddBankModal 
                    // handleNewBank={handleNewBank}
                 />
                </div>
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    Banks
                  </div>
                  <div className="card-body">
                  <div>
                        <input name="" id="" onChange={(event) =>this.handleOnSearch(event, event.target.value)} className="form-control mb-3" type="text" placeholder="Search..." />
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          {/* <th className="text-center">Actions</th> */}
                          <th className="text-center">Bank Name</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.data.length > 0 ? (
                          this.state.data.map((item) => (
                            <tr>
                              <td>{item.name}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={7}>Loading...</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </main>

            <Footerbar />
          </div>
        </div>


      </React.Fragment>
    );
  }
}
export default Bank;
