import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { userLogin } from "../../Utilities/api";
import { verifyJwt } from "../../Utilities/processJWT";

export const SignIn = function () {
  const [showmsg, setShowMsg, setRedirect, setDecoded] = useState("");
  const user = sessionStorage.getItem("user");

  return (
    <Formik
      initialValues={{
        username: user,
        password: "",
        isLoggedIn: false,
        rememberMe: false,
      }}
      onSubmit={(values, { setSubmitting }, props) => {
        setTimeout(() => {
          userLogin({
            username: values.username,
            password: values.password,
          })
            .then((response) => {
              console.log(response);
              if (response && response.status === 400) {
                setShowMsg("Username or password incorrect");
                setSubmitting(false);
                // } else if (response.status === 200 && response.data.token) {
              } else if (response.status === 200) {
                console.log("resp", response);
				/*
					if (!verifyJwt(response.data.token)) {
                  const decoded = verifyJwt(response.data.token);
                  console.log("respeeee", decoded, response.data.token);
                  setSubmitting(false);
                  setShowMsg("Server Error");
					} else if (verifyJwt(response.data.token)) {
					console.log("happen");
					if (values.rememberMe) {
						sessionStorage.setItem("user", values.username);
						// sessionStorage.setItem("password", values.password);
					}
					localStorage.setItem("isLoggedIn", true);
					// localStorage.setItem("firstSignup", false);
					// localStorage.setItem("token", response.data.token);
					// localStorage.setItem("token", response.data.token);
					localStorage.setItem("redirect", false);
					setTimeout(() => {
						console.log("found");
						window.location.replace("#/dashboard");
					}, 2000);
					} else {
					setShowMsg("Opps! Server error");
					setSubmitting(false);
					console.log(values.rememberMe);
					// window.location.replace('#/dashboard');
					}
				*/
				setTimeout(() => {
					console.log("found");
					window.location.replace("#/dashboard");
				}, 2000);
              }
            })
            .catch(function (error) {
              setShowMsg("Opps! Server error");
              setSubmitting(false);

              // window.location.replace('#/dashboard');
            });
        }, 1000);
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required("Username must be provided"),
        password: Yup.string().required("No password provided."),
      })}
    >
      {(props) => {
        const toggle = (event) => {
          event.preventDefault();
          let showPassword = document.querySelector("#toggle-password");
          let password = document.querySelector("#password");
          if (event.target.className === "fa fa-fw fa-eye field-icon") {
            showPassword.className = "fa fa-fw fa-eye-slash field-icon";
            password.type = "text";
          } else {
            showPassword.className = "fa fa-fw fa-eye field-icon";
            password.type = "password";
          }
        };

        const {
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          nextStep,
          isChecked,
          handleRememberMe,
        } = props;

        return (
          // <section>
          <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
              <main>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-5 mt-5">
                      <div className="card shadow-lg border-0 rounded-lg mt-5">
                        <div className="card-header">
                          <h3 className="text-center font-weight-light my-4">
                            Admin | Login
                          </h3>
                        </div>
                        <div className="card-body">
                          <form onSubmit={handleSubmit}>
                            <div className="mb-4 mt-4">
                              <input
                                className="form-control"
                                id="inputEmail"
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="mb-5">
                              <input
                                className="form-control"
                                id="inputPassword"
                                name="password"
                                type="password"
                                placeholder="Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="d-grid gap-2">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? "Loading…" : "Login"}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
          // </section>
        );
      }}
    </Formik>
  );
};

class SignInForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showmsg: "",
      redirect: null,
      isChecked: "",
    };
  }

  handleRedirect = () => {
    return <Redirect to="/dashboard" />;
  };

  handleRememberMe = () => {
    console.log("isChecked", this.state.isChecked);
  };

  render() {
    return (
      <React.Fragment>
        <SignIn
          showmsg={this.state.showmsg}
          handleRedirect={this.handleRedirect}
          isChecked={this.state.isChecked}
          handleRememberMe={this.handleRememberMe}
        />
      </React.Fragment>
    );
  }
}

export default SignInForm;
