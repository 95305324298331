import axios from "axios";
import { checkJwt, checkAdminJwt } from "../Utilities/processJWT";


const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
const REACT_APP_BASE_URL_NETPOS = process.env.REACT_APP_BASE_URL_NETPOS;
const REACT_APP_BASE_URL_QR = process.env.REACT_APP_BASE_URL_QR;
const REACT_APP_BASE_URL_PBT = process.env.REACT_APP_BASE_URL_PBT;
const REACT_APP_BASE_URL_NIBSS = process.env.REACT_APP_BASE_URL_NIBSS;
const REACT_APP_BASE_URL_CONTACTLESS = process.env.REACT_APP_BASE_URL_CONTACTLESS;


export function userLogin(params) {
	// console.log({params, url: REACT_APP_BASE_NETPLUS_MERCHANT_URL});
	return axios
		.post(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/Auth/login`,
			{ username: params.username, password: params.password },
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response) {
			return response;
		})
		.catch(function (error) {
			return error.response;
		});
}

// async function for user registration
export const userRegister = async(params) => {
	const response = await axios
		.post(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/register`,
			{
				email: params.email, 
				password: params.password, 
				businessname: params.businessname, 
				fullname: params.fullname
			}, 
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}

export const passwordResetRequest = async(params) => {
	const response = await axios
		.post(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/password/reset`,
			{
				email: params.email
			}, 
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}
export const passwordTokenState = async(params) => {
	const response = await axios
		.post(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/token/validate`,
			{
				email: params.email, 
				token: params.token
			}, 
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}
export const resetPassword = async(params) => {
	const response = await axios
		.post(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/password/reset/change`,
			{
				email: params.email, 
				password: params.password, 
				token: params.token
			}, 
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}

export const updateMerchant = async(params) => {
	const response = await axios
		.put(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/details`,
			{
				// new_password
				password: params.password, 
				businessname: params.businessname, 
				fullname: params.fullname
			}, 
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}

export const addBank = async(bankName) => {
	const response = await axios
		.post(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/add_bank`,
			{
				name: bankName
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}

export const getBanks = async() => {
	const response = await axios
		.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/banks`,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}


