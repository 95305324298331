import React from "react";
import axios from "axios";
import { checkJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Sidebar from '../../Layouts/Sidebar';
import Footerbar from "../../Layouts/Footerbar";
import { Table } from "react-bootstrap";
import AddBankModal from "../Modals/AddBankModal";
import AddUserModal from "../Modals/AddUserModal";

const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class User extends React.Component {    
    constructor(props) {
        super(props);
   
        this.state = {
            data: [],
            DataisLoaded: false,
           
        };
        
    }
    
    componentDidMount = async() => {     
        const response = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/admins`, 
			{
				headers: {
					"Content-Type": "application/json",
				}
			}
		)
        console.log(response.data);
        this.setState({
            data: response.data,
            DataisLoaded: true,
        });
      
    }

    render() {
        return (
            <React.Fragment>
                 <Navbar/>
				<div id="layoutSidenav">
				<Sidebar/>
                    <div className="bg-white" id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <h3 className="mt-4">Users</h3>
                            <hr/>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-5">
                            <AddUserModal
                            />
                            </div>
                            <div className="card mb-4">
                                <div className="card-header">
                                    <i className="fas fa-table me-1"></i>
                                    Users
                                </div>
                                <div className="card-body">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                {/* <th>Action</th> */}
                                                <th>Username</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                            </tr>
                                        </thead>
                                    
                                        <tbody>
                                        {this.state.data.length > 0 ? (
                                            this.state.data.map((item) => (
                                                <tr>
                                                    <td>{item.username}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.role == 1 ? 'Admin': 'Admin'}</td>
                                                 
                                                </tr>
                                            ))
                                            ) : (
                                                <tr className="text-center">
                                                    <td colSpan={8}>Loading...</td>
                                                </tr>
                                            )}			
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </main>

                    <Footerbar/>
					</div>
				</div>	
            </React.Fragment>
        );
    }
}
export default User;
