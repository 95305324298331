import React from "react";
// import "bootstrap/dist/css/bootstrap.css";

import {
	BrowserRouter,
	Route,
	Switch,
	Redirect,
	HashRouter,
	Router,
} from "react-router-dom";

import SignInForm from "./Components/Auth/SignInForm";
import Transaction from "../src/Components/Transaction/Transaction";
import PartnerTransaction from "./Components/Transaction/PartnerTransaction";
import Dashboard from "../src/Components/Dashboard/Dashboard";
import Merchant from "./Components/Merchant/Merchant";
import StormTransaction from "./Components/Transaction/StormTransaction";
import Mandate from "./Components/Transaction/Mandate";
import Settlement from "./Components/Settlement/Settlement";
import WemaSettlement from "./Components/Settlement/WemaSettlement";
import Bank from "./Components/Setting/Bank";
import AssignBank from "./Components/Setting/AssignBank";
import User from "./Components/Setting/User";
import UnassignBank from "./Components/Setting/UnassignBank";
import AwaitingMerchant from "./Components/Setting/AwaitingMerchant";


const isLoggedIn = localStorage.getItem("token");


const Routing = () => {
	return (
		<Switch>
			<Route exact path="/">
				<SignInForm />
			</Route>
			{/* <Route exact path="/sign-up">
				<SignUpForm />
			</Route> */}

			<Route path="/dashboard">
				<Dashboard />
			</Route>
			<Route path="/merchants">
				<Merchant />
			</Route>
			<Route path="/transactions">
				<Transaction />
			</Route>
			<Route path="/partner-transactions">
				<PartnerTransaction />
			</Route>
			<Route path="/storm-transactions">
				<StormTransaction />
			</Route>
			<Route path="/settlements">
				<Settlement />
			</Route>
			<Route path="/wema-settlement">
				<WemaSettlement />
			</Route>
			<Route path="/mandates">
				<Mandate />
			</Route>
			<Route path="/banks">
				<Bank />
			</Route>
			<Route path="/users">
				<User />
			</Route>
			<Route path="/assign-bank/assigned">
				<AssignBank />
			</Route>
			<Route path="/assign-bank/unassigned">
				<UnassignBank />
			</Route>
			<Route path="/awaiting-merchant">
				<AwaitingMerchant />
			</Route>
		</Switch>
	);
};




function App() {
	return (
		<HashRouter>
			<Routing />
		</HashRouter>
	);
}

export default App;
