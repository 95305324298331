import React from "react";
import axios from "axios";
// import { checkJwt, checkAdminJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Footerbar from "../../Layouts/Footerbar";
import Table from 'react-bootstrap/Table';
import Fuse from "fuse.js";
import Pagination from "../../Utilities/Pagination";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Sidebar from '../../Layouts/Sidebar';
import TransactionModal from "../Modals/TransactionModal";


const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Transaction extends React.Component {    
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            allMerchant: [],
            DataisLoaded: false,
            excelReport: [],
            links: [],
            searchResult: [],
            start: "",
            search: "",
            pagesize: 20,
            page: 1,
            end: "",
            transaction_status: "",
            fileExtension : ".xlsx",
            fileType : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        };
    }
    
    componentDidMount = async() => {       
        const merchants = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchants`, 
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				},
                params: {
                    page: this.state.page,
                    pagesize: 10
                }
			}
		);

        const transactions = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction_details`,
			{
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + token
				},
                params: {
                    page: this.state.page,
                    pagesize: this.state.pagesize
                }
			}
		);
       
       
        this.setState({
            items: transactions.data,
            allMerchant: merchants.data,
            DataisLoaded: true,
        });  
        console.log({items: this.state.items});
    }

    exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: this.state.fileType });
        FileSaver.saveAs(data, fileName + this.state.fileExtension);
    };

    handleExcelReport = (e, start, end, transaction_status) => {
        e.preventDefault();

        if(start == null || start === "") start = "2020-01-01";
        if(end == null || end === "") end = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction_export`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    start: start,
                    end: end,
                    status: transaction_status
                }
            }
        ).then((response) => {
            console.log({response, countResponseVale: response.data});
            if(response.data.length > 0){
                this.setState({
                    excelReport: response.data
                });
                this.exportToCSV(response.data, "transaction_report_from_" + start + "_to_" + end);
            }else{
                alert("No record found");
            }
            this.setState({
                excelReport: response.data
            });
        });
    }
    handlePDFReport = (e, start, end, transaction_status) => {
        // preventDefault();
        e.preventDefault();
        // const token = checkJwt();
        if(start == null || start === "") start = "2020-01-01";
        if(end == null || end === "") end = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`,
            {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: "Bearer " + token
                },
                params: {
                    start: start,
                    end: end,
                    status: transaction_status
                }
            }
        ).then((response) => {
            // console.log({response});
        });
    }

    handleOnSearch = (e, search) =>{
        e.preventDefault();
        const fuse = new Fuse(this.state.items, {
            keys: [
                'merchant_id', 'trans_id', 'amount',
                'transaction_status','full_name',
                'card_scheme','gateway','currency'
            ],
            includeScore: true,
            includeMatches: true,
        });

        const results = fuse.search(search);
        console.log({len: results.length});
        if(results.length > 0){
            console.log({data: results.map((res => res.item))});
                this.setState({
                    items: results.map((res => res.item))
                })
            // )
        }else{
            this.setState({
                items: this.state.items
            })
        }
    }

    onNextPage= async()=>{
        this.setState({
            page: this.state.page+1
        })
        const transactions = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction_details`,
			{
				headers: {
					"Content-Type": "application/json",
				},
                params: {
                    page: this.state.page + 1,
                    pagesize: this.state.pagesize
                }
			}
		);
        this.setState({
            items: transactions.data
        })
        console.log({page: this.state.page , transactions: transactions.data });
    }



    render() {
        return (
            <React.Fragment>
                <Navbar/>
				<div id="layoutSidenav">
				<Sidebar/>
                <div className="bg-white" id="layoutSidenav_content">
                <main>
                    <div className="container-fluid px-4">
                    <h4 className="mt-4">Transactions</h4>
                    <div className="row">
                    <hr/>
                    <div className="col-md-12">
                    <h5>Filter Report Criteria</h5>
                    <form>
                    <div className="row">
                    <div className="col-md-2">
                            <label>From</label>
                            <input 
                                type="date" name="start" 
                                className="form-control"
                                onChange={(event) => this.setState({start: event.target.value})}
                            />
                        </div>
                        <div className="col-md-2">
                            <label>To</label>
                            <input 
                                type="date" name="to" className="form-control"
                                onChange={(event) => this.setState({end: event.target.value})}
                            />
                        </div>
                        <div className="col-md-2">
                            <label>Transaction Status</label>
                            <select name="transaction_status" className="form-control"
                            onChange={(event) => this.setState({transaction_status: event.target.value})}
                            >
                                <option value="all">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Success">Success</option>
                                <option value="Failed">Failed</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label>Merchants</label>
                            <select name="merchant" className="form-control" id="js-example-basic-single">
                                <option value="all">All Merchants</option>
                                {this.state.allMerchant.length > 0 ? (
                                    this.state.allMerchant.map((item) => (
                                        <option value={item.merchantid}> {item.businessname} - {item.merchantid}</option>
                                    )
                                )):(
                                        <option></option>
                                    )}
                            </select>
                        </div>
                        <div className="col-md-3">
                            {/* <input type="submit" name="pdf" value="PDF Report" className="btn btn-primary btn-fixed" style={{ marginTop: "25px" }}/> */}
                            <input 
                                type="submit" name="excel" value="Excel Report" 
                                className="btn btn-success btn-fixed" style={{ marginTop: "25px" }}
                                onClick={(event) => this.handleExcelReport(event, this.state.start, this.state.end, this.state.transaction_status)}
                            />
                        </div>
                    </div>
                    </form>
                    </div>
                </div>
                <hr/>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Transactions
                            </div>
                            <div className="card-body">
                                <div>
                                    <input name="" id="" onChange={(event) =>this.handleOnSearch(event, event.target.value)} className="form-control mb-3" type="text" placeholder="Search..." />
                                </div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Actions</th>
                                            <th>Merchant</th>
                                            <th>Customer</th>
                                            <th>Amount</th>
                                            <th>Currency</th>
                                            <th>Status</th>
                                            <th>Date</th>       
                                            <th>Time</th>       
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.items.length > 0 ? (
                                            this.state.items.map((item) => (
                                                <tr>
                                                    <TransactionModal 
                                                    merchant_id={item.merchant_id}
                                                    trans_id={item.trans_id}
                                                    amount={item.amount}
                                                    transaction_status={item.transaction_status}
                                                    full_name={item.full_name}
                                                    card_scheme={item.card_scheme}
                                                    gateway={item.gateway}
                                                    currency={item.currency}
                                                    order_time={item.order_time}
                                                    />
                                                    <td>{item.merchant_id}</td>
                                                    <td>{item.full_name}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.currency}</td>
                                                    <td>{item.transaction_status}</td>
                                                    <td>{item.order_time.split(' ')[0]}</td>
                                                    <td>{item.order_time.split(' ')[1]}</td>
                                                </tr>
                                            ))
                                            ) : (
                                                <tr className="text-center">
                                                    <td colSpan={8}>Loading...</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </Table>
                                <div>
                                    <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item disabled">
                                        <button className="page-link" href="#" tabindex="-1">Previous</button>
                                        </li>
                                        <li className="page-item"><button className="page-link" onClick={this.onNextPage}>Next</button></li> 
                                    </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footerbar/>
					</div>
				</div>	
            </React.Fragment>
        );
    }
}
export default Transaction;
