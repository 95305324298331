import React from "react";
import axios from "axios";
import Navbar from "../../Layouts/Navbar";
import Sidebar from "../../Layouts/Sidebar";
import Footerbar from "../../Layouts/Footerbar";
import { Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const REACT_APP_BASE_NETPLUS_MERCHANT_URL =
  process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class AwaitingMerchant extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        data: [],
        banks: [],
        excelReport: [],
        fullname: "",
        businessname: "",
        email: "",
        currentpassword: "",
        newpassword: "",
        phone: "",
        accountno: "",
        website: "",
        DataisLoaded: false,
        textValue: "",
        webhook_url: "",
        fileExtension : ".xlsx",
        fileType : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    };
  }

  componentDidMount = async () => {
    // const token = checkJwt();
    const response = await axios.get(
      `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchant_awaiting_approval`,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token
        },
      }
    );
    console.log(response.data);
    this.setState({
      data: response.data.data,
      DataisLoaded: true,
    });
  };

  exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: this.state.fileType });
    FileSaver.saveAs(data, fileName + this.state.fileExtension);
};

handleExcelReport = (e) => {
    e.preventDefault();
    axios.get(
        `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/merchant_awaiting_approval`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    ).then((response) => {
        console.log({response, countResponseVale: response.data});
        if(response.data.data.length > 0){
            this.setState({
                excelReport: response.data.data
            });
            this.exportToCSV(response.data.data, "merchant_awaiting_approval");
        }else{
            alert("No record found");
        }
        this.setState({
            excelReport: response.data.data
        });
    });
}

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div id="layoutSidenav">
          <Sidebar />
          <div className="bg-white" id="layoutSidenav_content">
            <main>
              <div class="container-fluid px-4">
                <h3 class="mt-4">Awaiting Merchants</h3>
                <hr />
                <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                    <input 
                        type="submit" name="excel" value="Export Awaiting Merchant" 
                        className="btn btn-success btn-fixed" style={{ marginTop: "25px" }}
                        onClick={(event) => this.handleExcelReport(event)}
                    />
                </div>
                <div class="card mb-4">
                  <div class="card-header">
                    <i class="fas fa-table me-1"></i>
                    Awaiting Merchants
                  </div>
                  <div class="card-body">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Merchant ID</th>
                          <th>Business Name</th>
                          <th>Email</th>
                          <th>Date Registed</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* <tr>
                            <td>
                            <a href="" class=""  title="view test details and approve" ><i class=" btn btn-success fa fa-eye"></i></a>
                        </tr> */}
                        {this.state.data.length > 0 ? (
                          this.state.data.map((item) => (
                            <tr>
                              <td>{item.merchantid}</td>
                              <td>{item.merchantid}</td>
                              <td>{item.businessname}</td>
                              <td>{item.email}</td>
                              <td>{item.dateregistered.split(" ")[0]}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={7}>Loading...</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </main>

            <Footerbar />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AwaitingMerchant;
