import React from "react";
import axios from "axios";
import { checkJwt, checkAdminJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Footerbar from "../../Layouts/Footerbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Sidebar from "../../Layouts/Sidebar";
import { Table } from "react-bootstrap";

const REACT_APP_BASE_NETPLUS_MERCHANT_URL =
  process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Settlement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
      excelReport: [],
      startDate: "",
      endDate: "",
      transaction_status: "",
      fileExtension: ".xlsx",
      fileType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    };
  }

  componentDidMount = async () => {
    const token = checkJwt();
    const response = await axios.get(
      `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transactions`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    // const dataSet = response.data.transactions.map((item) => (
    //     [item.trans_id, item.order_id, item.full_name, item.amount, item.transaction_status, item.narration, item.created_at]
    // ));

    this.setState({
      items: response.data.transactions,
      DataisLoaded: true,
    });
  };

  exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: this.state.fileType });
    FileSaver.saveAs(data, fileName + this.state.fileExtension);
  };

  handleExcelReport = (e, startDate, endDate, transaction_status) => {
    // preventDefault();
    e.preventDefault();

    const token = checkJwt();
    if (startDate == null || startDate === "") startDate = "2020-01-01";
    if (endDate == null || endDate === "")
      endDate = new Date().toISOString().slice(0, 10);
    if (transaction_status == null || transaction_status === "")
      transaction_status = "all";

    axios
      .get(`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: {
          startDate: startDate,
          endDate: endDate,
          status: transaction_status,
        },
      })
      .then((response) => {
        // console.log({response, countResponseVale: response.data.transactions.length});
        if (response.data.transactions.length > 0) {
          this.setState({
            excelReport: response.data.transactions,
          });
          this.exportToCSV(
            response.data.transactions,
            "transaction_report_from_" + startDate + "_to_" + endDate
          );
        } else {
          alert("No record found");
        }
        this.setState({
          excelReport: response.data.transactions,
        });
      });
  };
  handlePDFReport = (e, startDate, endDate, transaction_status) => {
    // preventDefault();
    e.preventDefault();
    const token = checkJwt();
    if (startDate == null || startDate === "") startDate = "2020-01-01";
    if (endDate == null || endDate === "")
      endDate = new Date().toISOString().slice(0, 10);
    if (transaction_status == null || transaction_status === "")
      transaction_status = "all";

    axios
      .get(`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: {
          startDate: startDate,
          endDate: endDate,
          status: transaction_status,
        },
      })
      .then((response) => {
        // console.log({response});
      });
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div id="layoutSidenav">
          <Sidebar />
          <div className="bg-white" id="layoutSidenav_content">
            <main>
              <div className="container-fluid px-4">
                <h3 className="mt-4">Settlements</h3>
                <hr />

                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    Settlements
                  </div>
                  <div className="card-body">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Merchant ID</th>
                          <th>Merchant Name</th>
                          <th>Total Transaction</th>
                          <th>Settlement %</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <a
                              href=""
                              title="view <?php echo $value->businessname?>'s Settlement Info"
                            >
                              <i className="fa fa-eye  btn btn-xs btn-primary "></i>
                            </a>
                            <button
                              className="btn btn-xs text-success"
                              data-bs-toggle="modal"
                              title=""
                              data-bs-target=""
                            >
                              <i className="btn btn-success btn-xs fa fa-edit"></i>
                            </button>
                          </td>
                          <td>Dummy</td>
                          <td>Dummy</td>
                          <td>Dummy</td>
                          <td>Dummy</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>

              {/* loop */}
              <div
                className="modal fade"
                id="editModal<?php echo $value->merchantid;?>"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myModalLabel">
                        Edit Merchant Settlement %
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                    </div>

                    <form>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">
                                Merchant Business Name
                              </label>
                              <input type="hidden" name="id" value="dummy" />
                              <input
                                type="text"
                                className="form-control"
                                readonly
                                value="dummy"
                                name="businessname"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">
                                Merchant ID
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                readonly
                                value="dummy"
                                name="merchantid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="form-group
                                                "
                            >
                              <label for="appointment_number">
                                Settlement %{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value="dummy"
                                name="settlement_percent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <input
                          type="submit"
                          className="btn btn-success"
                          value="Update"
                        />
                        <button
                          type="button"
                          className="btn btn-default"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>
            <Footerbar />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Settlement;
