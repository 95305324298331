import React from 'react';

class Navbar extends React.Component {

	logout() {
        localStorage.clear();
        window.location.href = '/';
    }
    render() {
        return (
            <React.Fragment>
				<nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
					<a className="navbar-brand ps-3" href="#">NetPlus Admin</a>
					<button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button>
					<form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
					</form>
					<ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
						<li className="nav-item dropdown">
							<a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
							<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
								<li><a className="dropdown-item" href="#" onClick={(event) => this.logout()} >Logout</a></li>
							</ul>
						</li>
					</ul>
				</nav>
            </React.Fragment>
        )
    }
}

export default Navbar;