import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addBank } from '../../Utilities/api';

function AddUserModal(props) {
  const [show, setShow] = useState(false);
  const [bankName, setBankName] = useState({
    bankName: ''
  });
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBankName((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log({bankName, bank: bankName.name});
    addBank(
      bankName.name
    )
    .then((response) => {
        console.log(response);
        if (response && response.status == 200) {
          handleClose();
          window.location.reload();
          
        } else {
          handleClose();
          window.location.reload();
          console.log("resp", response);
        }
      })
  };

 

  return (
    <>
      <button variant="primary" className='btn btn-primary' onClick={handleShow}>
      Add User
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Bank</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={handleSubmit} className='' id='bankForm'>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          // value={bankName.bankName}
                          onChange={handleInputChange}
                          placeholder="Bank Name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>              
        </Modal.Body>
        <Modal.Footer>
          <button form='bankForm' className='btn btn-success'>Save</button>
          {/* <Button variant="success" onClick={handleClose}>
            Save
          </Button> */}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddUserModal;

