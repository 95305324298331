import React from 'react';
import Linkbar from './Footerbar';

class Sidebar extends React.Component {
    render() {
        return (
            <React.Fragment>
            {/* <div className="left side-menu">
                <div className="sidebar-inner slimscrollleft">

                    <div id="sidebar-menu">
                        <ul>
                            <li className="has_sub">
                                <a href="/#/dashboard" className="waves-effect"><i className="mdi mdi-view-dashboard"></i><span> Dashboard </span> </a>
                            </li>
                            <li className="has_sub">
                                <a href="/#/merchants" className="waves-effect"><i className="mdi mdi-account-multiple-outline"></i><span> Merchants </span> </a>
                            </li>
                            <li className="has_sub">
                                <a href="/#/transactions" className="waves-effect"><i className="mdi mdi-cash-multiple"></i><span> Transactions </span> </a>
                            </li>
                            <li className="has_sub">
                                <a href="/#/settlements" className="waves-effect"><i className="glyphicon glyphicon-briefcase"></i><span> Settlements </span> </a>
                            </li>
                            <li className="has_sub">
                                <a href="/#/customers" className="waves-effect"><i className="mdi mdi-account-star"></i><span> Customers </span> </a>
                            </li>
                            <li className="has_sub">
                                <a href="/#/wallets" className="waves-effect"><i className="mdi mdi-wallet"></i><span> Wallet </span> </a>
                            </li>
                            <li className="has_sub">
                                <a href="/#/settings" className="waves-effect"><i className="mdi mdi-settings"></i><span> Settings </span> </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div> */}
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                        <div className="sb-sidenav-menu">
                            <div className="nav">
                                <a className="nav-link" href="/#/dashboard">
                                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                    Dashboard
                                </a>
                                <a className="nav-link" href="/#/merchants">
                                    <div className="sb-nav-link-icon"><i className="fa fa-users"></i></div>
                                    Merchants
                                </a>
                                
                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div className="sb-nav-link-icon"><i className="fa fa-bar-chart"></i></div>
                                    Transactions
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <a className="nav-link" href="/#/transactions">General Transactions</a>
                                        <a className="nav-link" href="/#/partner-transactions">Partner Merchant Transactions</a>
                                        <a className="nav-link" href="/#/storm-transactions">Storm Transactions</a>
                                    </nav>
                                </div>
                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                    <div className="sb-nav-link-icon"><i className="fa fa-university" aria-hidden="true"></i></div>
                                    Settlements
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <a className="nav-link" href="/#/settlements">General Settlements</a>
                                        <a className="nav-link" href="/#/wema-settlement">Wema Settlements</a>
                                    </nav>
                                
                                </div>
                                <a className="nav-link" href="/#/mandates">
                                    <div className="sb-nav-link-icon"><i className="fas fa-ban"></i></div>
                                    Cancel Mandate
                                </a>
                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings">
                                    <div className="sb-nav-link-icon"><i className="fas fa-cog"></i></div>
                                    Settings
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="collapseSettings" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <a className="nav-link" href="/#/banks">Banks</a>
                                        <a className="nav-link" href="/#/assign-bank/assigned">Assign Bank</a>
                                        <a className="nav-link" href="/#/awaiting-merchant">Awaiting Confirmation Merchant</a>
                                        <a className="nav-link" href="/#/users">Users</a>
                                    </nav>
                                
                                </div>
                                
                                
                            </div>
                        </div>
                        <div className="sb-sidenav-footer">
                            <div className="small">Logged in as: 
                            <span> Admin</span>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default Sidebar;