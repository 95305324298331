import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function TransactionModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button variant="primary" onClick={handleShow}>
        <i className="fa fa-eye btn btn-success btn-xs"></i>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            <div className="modal-body
            ">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group
                        ">
                            <label className="" for="">Merchant ID</label>
                            <input 
                              className='form-control'
                              defaultValue={props.merchant_id}
                              disabled={true}

                            />
                        </div>
                    </div>
                
                    <div className="col-md-4">
                    <div className="form-group">
                            <label className="" for="patient_name">Transaction Reference</label>
                             <input 
                              className='form-control'
                              defaultValue={props.trans_id}
                              disabled={true}

                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="" for="patient_name">Transaction Amount</label>
                             <input 
                              className='form-control'
                              defaultValue={props.amount}
                              disabled={true}

                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group
                        ">
                            <label className="mt-3" for="appointment_number">Transaction Status</label>
                             <input 
                              className='form-control'
                              defaultValue={props.transaction_status}
                              disabled={true}

                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="mt-3" for="patient_name">Customer Name</label>
                             <input 
                              className='form-control'
                              defaultValue={props.full_name}
                              disabled={true}

                            />
                            
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="mt-3" for="patient_name">Card Scheme</label>
                             <input 
                              className='form-control'
                              defaultValue={props.card_scheme}
                              disabled={true}

                            />
                        </div>
                    </div>
                
                    <div className="col-md-4">
                        <div className="form-group
                        ">
                            <label className="mt-3" for="appointment_number">Transaction Gateway</label>
                             <input 
                              className='form-control'
                              defaultValue={props.gateway}
                              disabled={true}

                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                            <label className="mt-3" for="current_patient_type">Transaction Currency</label>
                             <input 
                              className='form-control'
                              defaultValue={props.currency}
                              disabled={true}

                            />
                        </div>
                    
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="mt-3" for="patient_name">Transaction Date</label>
                            <input 
                              className='form-control'
                              defaultValue={props.order_time ? props.order_time.split(' ')[0] : ''}
                              disabled={true}

                            />
                            
                        </div>
                    </div>
                </div>
            </div>                  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TransactionModal;

