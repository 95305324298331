import React from 'react';

class Footerbar extends React.Component {
    render() {
        return (
            <React.Fragment>
           
           <footer className="py-4 bg-light mt-auto">
                <div className="container-fluid px-4">
                    <div className="d-flex align-items-center justify-content-between small">
                        <div className="text-muted">Copyright &copy; NetPlus Limited</div>
                    </div>
                </div>
            </footer>
            </React.Fragment>
        )
    }
}

export default Footerbar;