import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AssignModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button variant="primary" onClick={handleShow}>
        <i className="fa fa-pencil btn btn-success"></i>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Bank to Merchant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form action="">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group
                        ">
                            <label for="">Merchant Business Name</label>
                            <input type="hidden" name="id" value="2" />
                            <input type="text" 
                              className="form-control" 
                              disabled={true} 
                              defaultValue={props.businessname} 
                              name="businessname" 
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group
                        ">
                            <label for="">Merchant ID</label>
                            <input type="text" 
                              className="form-control" 
                              disabled={true} 
                              defaultValue={props.merchantid} 
                              name="merchantid" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group
                        ">
                            <label for="" className='mt-3'>Bank</label>
                            <select className="form-control" name="bank" id="bank2">
                                <option value="">Select Bank</option>
                                    <option value="15">Union Bank</option>	
                                    <option value="16">Unity Bank</option>
                                    <option value="17">Wema Bank</option>
                                    <option value="19">Providus Bank</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>                
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AssignModal;

